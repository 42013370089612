<template>
<div class="boDetails">
  <header class="boDetails_BoDetails">
    <img :src="banners" alt />
  </header>
  <body class="boDetails_body" :class="Scales ? 'Scales' : ''">
  <div class="boDetails_titles"><img alt="" src="../assets/titleImg.png" />
        <img id="boDetails_TitleImg" src="../assets/bgjs.png">
        <img alt="" src="../assets/titleImg.png" />
        </div>
        <div v-html="detail">
          
        </div>
  </body>
  <Footer v-if="falg"></Footer>
</div>
</template>

<script>
import Footer from "../components/footer";
import { fetch } from "../api/https";
export default {
  data() {
    return {
      banners: "",
      detail: "",
      falg: false,
      Scales:false
    };
  },
  components: {
    Footer
  },
  created() {
    fetch(`/menu/${9}`)
      .then(res => {
        this.detail = res.data.menu_content;
        document.title = res.data.menu_seo_title;
        this.falg = true;
      })
      .catch(err => {});
      fetch('/getmenu')
      .then(res => {
        this.banners = res.data[1].child[0].menu_img;
      })
      .catch(err => {});
    // this.axio()
  },
  mounted() {
        var width = document.documentElement.clientWidth;
        var height = document.documentElement.clientHeight;
        if(width>1024 &&  width< 1400){
          this.Scales = true
        }
  },
  methods: {
    axio() {
      // this.$route.query.id
      fetch(`/menu/10`)
        .then(res => {
          this.detail = res.data.detail;
        })
        .catch(err => {});
    },
    mouted() {

    }
  }
};
</script>
<style>
@media screen and (max-width:1400px){ 
  .zhici_en_bodyDiv {
    width: 1366px !important;
  }
  .boDetails_BottomTwo{
    width: 1366px !important;

  }
 }
</style>
<style>
.boDetails_BoDetails {
  width: 100%;
  height: 670px;
  
  /* background: url("../assets/img/banner5.png"); */
}
.boDetails_BoDetails img {
  height: 100%;
  width: 100%;
}
>>> .boDetails_body {
  width: 100%;
  height: auto;
  padding-bottom: 80px;
  min-height: 120%;
  margin: 0 auto;
}
.boDetails_Detailsed{
    font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #888888;
  line-height: 48px;
}
.boDetails_Details {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #888888;
  line-height: 48px;
}
.boDetails_Details img {
  /* width: 1238px;
  height: 549px; */
  margin-top: 68px;
  margin-bottom: 62px;
}
.boDetails_bodyDiv {
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
  /* background-color: yellow; */
  margin-top: 60px;
}
.boDetails_titles {
  font-weight: 800;
  font-size: 45px;
  text-align: center;
  margin: 0 auto;
  margin-top: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
}
.boDetails_titles img {
  width: 26px;
  height: 21px;
  margin-left: 14px;
  margin-right: 14px;
}
.boDetails_YellowTitle {
  margin-top: 85px;
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #bd955d;
  line-height: 48px;
}
.boDetails_DetailsTitle {
  width: 182px;
  /* line-height: 32px; */
  color: rgb(188, 148, 93);
  /* height: 30px; */
  background: url("../assets/museumIntro/BodetailsImg.png") no-repeat;
  line-height: 40px;
  margin-bottom: 10px;
  margin: 0 auto;
  margin-bottom: 56px;
  background-size: contain;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #bd955d;
  line-height: 59px;
}  
.boDetails_outText {
  color: #bd955d;
  font-size: 24px;
  font-weight: 500;
  line-height: 48px;
  font-family: PingFang-SC-Bold;
}
.boDetails_mom {
  margin-top: 43px;

}
.boDetails_mot {
  margin-top: 30px;
}
.boDetails_imageList {
  margin-top: 71px;
  margin-bottom: 81px;
}
.boDetails_Imgs {
  margin-top: 71px;
  width: 1398px;
  padding-left: 78px;
  padding-right: 81px;
  margin-bottom: 81px;
  margin: 0 auto;
  box-sizing: border-box;
}
.boDetails_Imgs img {
  width: 384px;
  margin-right: 37px;
  height: 435px;
}
footer {
  width: 100%;
  height: 450px;
}
.boDetails_BottomTwo{
  margin: 0 auto;
  text-align: center;
font-size: 18px;
font-family: PingFang SC;
font-weight: 500;
color: #888888;
line-height: 48px;
margin-bottom: 50px;
}
.boDetails_Bottomone{
  text-align: center ;
  margin-bottom: 162px;
}
#boDetails_TitleImg{
  height: 44px;
  width: 178px;
}
@media screen and (max-width: 1440px){
  .boDetails_Imgs {
  margin-top: 71px;
  width: 1350px;
  padding-left: 78px;
  padding-right: 81px;
  margin-bottom: 81px;
  margin: 0 auto;
  box-sizing: border-box;
}
.boDetails_Imgs img {
  width: 384px;
  margin-right:1px !important;
  height: 435px;
}
}
@media screen and(max-width: 1200px){
  /* .boDetails_Imgs {
    width: 100% ;
} */
}
</style>
